<template>
  <div class="journal">
    XBTUSD,ETHUSD,BCHUSD trades can be saved- results are fees inclueded- available in CSV format- chart analysis in construction- need an account for data storage (free) - do not hesitate to send me your comments <a href="https://twitter.com/calcmex?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false" style="color:#ab885a;">@calcmex on twitter for the latest events</a>
<Trade/>
{{todos12a}}={{todos12}}
<span class='export ' style="color:burlywood;">
 <download-csv      
                      :data= "todos2" 
                      name="Trading_Journal.csv" >  <md-icon style="color:burlywood;">cloud_download  </md-icon>     
                      <strong>    DOWNLOAD JOURNAL .CSV</strong>
                       </download-csv></span>
<md-table style="overflow-x:auto; background:  #585858; border-radius: 10px 10px 10px 10px;;table-layout: fixed; width: 100%:" v-model= "todos" md-sort="date" md-sort-order="asc" md-card  md-fixed-header >
                <md-table-toolbar>
                     
                    <h1 class="md-title" style="color:burlywood;"><strong>TRADING JOURNAL</strong></h1>
               
 
                  
                   
          
 
                </md-table-toolbar>

                  <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }" >
        <div class="md-toolbar-section-start">{{ getAlternateLabel(count) }}</div>

        <div class="md-toolbar-section-end">
           
          <md-button @click= " deleteToDo()" class="md-icon-button" >
            <md-icon>delete</md-icon>
          </md-button>
        </div>
      </md-table-toolbar>

      <md-table-row  slot="md-table-row" slot-scope="{ item }"  md-selectable="multiple" md-auto-select>
        <md-table-cell style="width:6%" md-label="Date" md-sort-by="date" >{{item.trade.date}}</md-table-cell>
        <md-table-cell style="width:6%" md-label="Exchange" md-sort-by="exchange">{{item.trade.exchange}}</md-table-cell>
        <md-table-cell style="width:6%" md-label="Pair" md-sort-by="pair">{{item.trade.pair}}</md-table-cell>
        <md-table-cell style="width:6%" md-label="Direction" md-sort-by="direction">{{item.trade.direction}}</md-table-cell>
        <md-table-cell style="width:6%" md-label="Entry" md-sort-by="entry">{{item.trade.entry}}</md-table-cell>
        <md-table-cell style="width:6%" md-label="Stop Loss" md-sort-by="stopLoss">{{item.trade.stopLoss}}</md-table-cell>
        <md-table-cell style="width:6%" md-label="Target" md-sort-by="target" md-numeric>{{item.trade.target}}</md-table-cell>
        <md-table-cell style="width:6%" md-label="Exit" md-sort-by="exitReal">{{item.trade.exitReal}}</md-table-cell>
         <md-table-cell style="width:6%" md-label="Position Size" md-sort-by="positionSize">{{item.trade.positionSize}}</md-table-cell>
         <md-table-cell style="width:6%" md-label="Maker Fees" md-sort-by="">Entry {{item.trade.entryMaker}},Exit {{item.trade.exitMaker}}</md-table-cell>
        <md-table-cell style="width:6%" md-label="$ PnL" md-sort-by="result$">{{item.trade.result$}}</md-table-cell>
        <md-table-cell style="width:6%" md-label="Xbt PnL" md-sort-by="resultXbt">{{item.trade.resultXbt}}</md-table-cell>
        <md-table-cell style="width:6%" md-label="Risk Reward" md-sort-by="resultPorCent">{{item.trade.riskReward}}</md-table-cell>
         <md-table-cell  style="width:16%" md-label="Comments" md-sort-by="comments">{{item.trade.comments}}</md-table-cell>
         <md-table-cell style="max-width:1px"  md-label="Id"  ref="id1" >{{item.id}}</md-table-cell>
      </md-table-row>

    </md-table>

<Charts v-bind:todos9aa="todos9aa" :todos9ab= "todos9ab" :todos10aa= "todos10aa" :todos10ab= "todos10ab" :todos11aa= "todos11aa" :todos11ab= "todos11ab" :todos12= "todos12" :todos12a= "todos12a" :todos12b= "todos12b"/>
  </div>
</template>

<script>

import firebase from 'firebase/app';
import JsonCSV from 'vue-json-csv'
import 'firebase/auth';
import Trade from '@/components/Trade.vue';
import Charts from '@/components/Charts.vue';

  export default {
    name: 'journal',
    data() {
      return {
        selected: [],
todos: [],
todos2:[],
todos3:[],
todos3a:[],
todos4:[],
todos5:[],
todos8:[],
todos7:[],
todos9:[],

todos10:[],
todos11:[],
todos12:[],
todos12a:[],
     trade:  {
          date: "",
          exchange: "",
          direction: "",
          pair:"",
          entry: "",
           stopLoss:"",
            target: "",
             exitReal:"",
             positionSize:"",
              result$:"",
               resultXbt:"",
                riskReward: "",
                 comments: " ",
                 id1:'',
                        }
             }        
    }, 
components:{
Trade,
Charts },
  
    created: function() {
this.getJournal()
    },
   
     methods: {    
 
        getAlternateLabel (count) {
         console.log(this.docId)
        let plural = ''
        if (count > 1) {
          plural = 's'
        }
        return `${count} trade${plural} selected`
      },

       addTrade() {
    firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .collection("tradeData")
        .add({
            trade: this.trade,
            createdAt: new Date(),           
        })
},
 

async getJournal() {
    var todosRef = await firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .collection("tradeData");

   todosRef.onSnapshot(snap => {
    
        this.todos = [];
        this.todos2=[];
        this.todos3=[];
        this.todos3a=[];
        snap.forEach(doc => {
            var todo = doc.data();
            todo.id = doc.id;
            this.todos.push(todo);
    
        this.todos2.push(doc.data().trade);
      // this.todos3.push(doc.data());
          //  console.log(this.todos2)
           
        });  
 //moyene riskreward total       
this.todos2.forEach((e) => { this.todos3.push(e.riskReward);});
 this.todos3a =this.todos3.map(Number)         
 this.todos3aa = this.todos3a.reduce(function (result, item) {return result + item;}, 0);
this.todos3b =this.todos3a.length
this.todos3c =(this.todos3aa/this.todos3a.length).toFixed(2);
 /////
   //moyene result$ total       
this.todos2.forEach((e) => { this.todos5.push(e.result$);});
 this.todos5a =this.todos5.map(Number)     
 //cumul      
 this.todos5aa = this.todos5a.reduce(function (result, item) {return result + item;}, 0);
this.todos5b =this.todos5a.length
this.todos5c =(this.todos5aa/this.todos5a.length).toFixed(2);
 /////  
   //moyene result$ total       
this.todos2.forEach((e) => { this.todos7.push(e.resultXbt);});
 this.todos7a =this.todos7.map(Number) 
 //cumul        
 this.todos7aa = this.todos7a.reduce(function (result, item) {return result + item;}, 0);
this.todos7b =this.todos7a.length
this.todos7c =(this.todos7aa/this.todos7a.length);
 /////  
  //moyene position sizetotal       
this.todos2.forEach((e) => { this.todos8.push(e.positionSize);});
 this.todos8a =this.todos8.map(Number)        
 this.todos8aa = this.todos8a.reduce(function (result, item) {return result + item;}, 0);
this.todos8b =this.todos8a.length
this.todos8c =(this.todos8aa/this.todos8a.length);
 //position size max
 this.todos8d=Math.max(...this.todos8a) 
 //position size min
 this.todos8dd=Math.min(...this.todos8a) 
 ///////////
 //nombre de buy, sell
 this.todos2.forEach((e) => { this.todos9.push(e.direction);});
 this.todos9a =this.todos9.map(String)  
this.todos9aa=this.todos9a .filter(x => x=='BUY').length
this.todos9ab=this.todos9a .filter(x => x=='SELL').length
this.todos9c =this.todos9a.length  //nombre trade total
this.todos9d=100/(this.todos9c/this.todos9aa).toFixed(2)  //pourcentage de buy
this.todos9dd=100/(this.todos9c/this.todos9ab).toFixed(2)  //pourcentage de sell
this.todos99={buy:this.todos9d, sell:this.todos9dd}
////////
//nombre de entry.maker
 this.todos2.forEach((e) => { this.todos10.push(e.entryMaker);});  
this.todos10aa =this.todos10.filter(x => x==true).length
this.todos10ab =this.todos10.filter(x => x==false).length
this.todos10c =this.todos10.length  //nombre trade total
this.todos10d=100/(this.todos10c/this.todos10aa).toFixed(2)  //pourcentage de true
this.todos10dd=100/(this.todos10c/this.todos10ab).toFixed(2)  //pourcentage de false
////////
//nombre de exit.maker
 this.todos2.forEach((e) => { this.todos11.push(e.exitMaker);});  
this.todos11aa=this.todos11.filter(x => x==true).length
this.todos11ab=this.todos11.filter(x => x==false).length
//this.todos11c =this.todos11.length  //nombre trade total
//this.todos11d=100/(this.todos11c/this.todos11aa).toFixed(2)  //pourcentage de true
//this.todos11dd=100/(this.todos11c/this.todos11ab).toFixed(2)  //pourcentage de false
////////
//this.todos12.push(['risk reward','date'])
 this.todos2.forEach((e) => {this.todos12.push(parseFloat(e.riskReward)  +','+e.date)});  
 //this.todos2.forEach((e) => this.todos12.push([(e.riskReward) + (e.date)]))
 //this.todos12a=this.todos12.map(Number)
 
 
 ////////////////////////+','+e.date]
 });  
},

deleteToDo() {
 var docId=this.$refs.id1.MdTable.selectedItems.__ob__.value[0].id
    firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .collection("tradeData")
        .doc(docId)
        .delete();
}
},
computed:{

}
  }
</script>
<style lang="scss" scoped>

.div {
  width: 100%;
 
}
.export{
  text-align: right;
}
/*
.md-table {
margin-top: 56px;
 
}*/
.md-table-row{
    height:100%;
   
}
.md-table-cell {
    background: #2f2f2f;
    color:burlywood;
    overflow:hidden;
  //  width: 6.66%;
  //  max-width:100px;
    white-space:wrap; 
overflow:visible;
text-overflow:ellipsis;


}

.md-selectable{
  background: #6f6f6f;
}
.md-auto-select{background: #6f6f6f;}
</style>

//overflow-x:auto;
//   <strong> DOWNLOAD JOURNAL .CSV</strong>
//  <md-button style="color:burlywood;" @click= " deleteToDo()"  ><md-tooltip md-direction="left">construction</md-tooltip>
 //           <md-icon style="color:burlywood;">input</md-icon> <br> Import CSV
//          </md-button>