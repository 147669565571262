<template>
  <div class="sign-up">
    <h3>Let's create a new account !</h3>
   

        <div  class="md-layout   md-alignment-top-center" >
      <div class="md-layout-item md-size-40 md-medium-size-30 md-small-size-20 md-xsmall-hide  "></div>
      <div class= "md-layout-item md-size-20 md-medium-size-40 md-small-size-hide md-xsmall-100  ">
        <md-field>
           <md-input class=input type="email" v-model="email" placeholder="Email"></md-input>
        </md-field>
    <md-field md-toggle-password>
         <md-input class="input  " type="password"  v-model="password" placeholder="Password" v-on:keyup.enter="signUp" ></md-input>
    </md-field>
     <md-button @click="signUp">Sign Up</md-button>
   </div>
   <div class="md-layout-item md-size-40 md-medium-size-30 md-small-size-20 md-xsmall-hide  "></div>
    </div>

    <span>or go back to <router-link to="/login">login</router-link>.</span>
  </div>
</template>

 <script>
  import firebase from 'firebase/app';
import 'firebase/auth';

  export default {
    name: 'signUp',
    data() {
      return {
        email: '',
        password: ''
      }
    },
    methods: {
      signUp: function() {
        firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then(
          ()=>  {
           this.$router.replace('home')
          },
          (err) => {
            alert('Oops. ' + err.message)
          }
        );
      }
    }
  }
</script>

 <style scoped>
  .sign-up {
    margin-top: 40px;
  }
h3 {
  margin-top:6%;
}
  span {
    display: block;
    margin-top: 20px;
    font-size: 16px;
  }
   button {
   
    background: #bdc3c7;
    cursor: pointer;
    border-radius: 15px;
  }
.md-field {
  background: #bdc3c7;
  border-radius: 6px;

}

</style>