<template>
  
<div><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
    <twitter>
    <a class="twitter-timeline" data-theme="dark" href="https://twitter.com/CalcMex/likes?ref_src=twsrc%5Etfw">Tweets Liked by @CalcMex</a>  
    </twitter>

</div>
 
</template>

<script>

  export default {
    name: 'Tweets',
    data() {
      return 
    },
   
  }
  

</script>

<style lang="scss" scoped >

.div{
  width: 100%;
  margin:auto; 
 min-height: width 300px;
}

</style>

