<template>
  <div class="disclaimer">

    <div>
        <p>Trading Cryptocurrencies on margin carries a high level of risk, and may not be suitable for everyone. Past performance is not indicative of future results. The high degree of leverage can work against you as well as for you. Before getting involved in Cryptocurrency trading you should carefully consider your personal venture objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss of some or all of your initial deposit and therefore you should not place funds that you cannot afford to lose. You should be aware of all the risks associated with Cryptocurrency trading, and seek advice from an independent financial advisor if you have any doubts. The information contained in this web page does not constitute financial advice or a solicitation to buy or sell any Cryptocurrency contract  of any type. Calcmex will not accept liability for any loss or damage.</p>
    </div>

  </div>
</template>

<script>

  export default {
    name: 'disclaimer',
    data() {
      return {
        
      }
    },
    
  }
</script>
<style>
.div {
  width: auto;
}
</style>