import { render, staticRenderFns } from "./Journal.vue?vue&type=template&id=7ff70ffa&scoped=true&"
import script from "./Journal.vue?vue&type=script&lang=js&"
export * from "./Journal.vue?vue&type=script&lang=js&"
import style0 from "./Journal.vue?vue&type=style&index=0&id=7ff70ffa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ff70ffa",
  null
  
)

/* custom blocks */
import block0 from "./Journal.vue?vue&type=custom&index=0&blockType=strong"
if (typeof block0 === 'function') block0(component)
import block1 from "./Journal.vue?vue&type=custom&index=1&blockType=md-button&style=color%3Aburlywood%3B&%40click=%20deleteToDo()"
if (typeof block1 === 'function') block1(component)

export default component.exports