import { render, staticRenderFns } from "./BitmexXrpUs.vue?vue&type=template&id=74951fc4&scoped=true&"
import script from "./BitmexXrpUs.vue?vue&type=script&lang=js&"
export * from "./BitmexXrpUs.vue?vue&type=script&lang=js&"
import style0 from "./BitmexXrpUs.vue?vue&type=style&index=0&id=74951fc4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74951fc4",
  null
  
)

/* custom blocks */
import block0 from "./BitmexXrpUs.vue?vue&type=custom&index=0&blockType=md-card-expand-trigger"
if (typeof block0 === 'function') block0(component)
import block1 from "./BitmexXrpUs.vue?vue&type=custom&index=1&blockType=md-card-expand-content"
if (typeof block1 === 'function') block1(component)
import block2 from "./BitmexXrpUs.vue?vue&type=custom&index=2&blockType=div&class=md-layout%20md-alignement-center-center%20"
if (typeof block2 === 'function') block2(component)

export default component.exports