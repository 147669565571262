
//import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebaseui';
import Vue from 'vue';
import Router from 'vue-router';
import Tweets from '@/views/Tweets';
import Binance_calculator from '@/views/Binance_calculator';
import BitmexEthUs from '@/views/BitmexEthUs';
import BitmexLtcUsd from '@/views/BitmexLtcUsd';
import BitmexBchUsd from '@/views/BitmexBchUsd';
import BitmexXrpUsd from '@/views/BitmexXrpUsd';
import Construction from '@/views/Construction';
import Instructions from '@/views/Instructions';
import Disclaimer from '@/views/Disclaimer';
import Journal from '@/views/Journal';
import Demo from '@/views/Bitmex-calculator';
import Home from '@/views/Home';
import Login from '@/views/Login';
import Login1 from '@/views/Login1';
import SignUp from '@/views/SignUp';
import './style.scss';
//import { MdButton, MdContent, MdCard,MdField,MdSwitch,MdTable,MdApp,} from 'vue-material/dist/components';
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import VueMeta from 'vue-meta';
//import { computeTradeBars } from 'tardis-dev';
//import Axios from 'axios';
//import JsonCSV from 'vue-json-csv';
//Vue.component('downloadCsv', JsonCSV);
//import VueGoogleCharts from 'vue-google-charts';

//Vue.use(VueGoogleCharts);
Vue.use(VueMaterial);
Vue.use(VueMeta);
//import GoogleSignInButton from 'vue-google-signin-button-directive'
//Vue.use(GoogleSignInButton)
/*Vue.use(MdButton)
Vue.use(MdContent)
Vue.use(MdCard)
Vue.use(MdField)
Vue.use(MdSwitch)
Vue.use(MdTable)
Vue.use(MdApp)*/
Vue.use(Router);
//Vue.use (Axios)
const router = new Router({
  mode:'history',
  routes: [
    
    {
      path: '/',
      redirect: '/home',
      meta:{
        title:'XBTUSD BitMEX Calculator',
       
      }
    },
    {
      path: '/bitmex-calculator',
      name: '/Bitmex-calculator',
      component: Demo,
      meta:{
       
        crypto:true,
        beta:false,
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta:{
       
        crypto:true,
        beta:false,
      }
    },
    {
      path: '/login1',
      name: 'Login1',
      component: Login1
    },
    {
      path: '/sign-up',
      name: 'SignUp',
      component: SignUp,
      meta:{
       
        crypto:true,
        beta:false,
      }
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: false,
        
          bitmex:true,
          beta:false,
        
      },
    },
    {
      path: '/instructions',
      name: 'Instructions',
      component: Instructions,
      meta:{
       
        crypto:true,
        beta:false,
      }
    },
    {
      path: '/construction',
      name: 'Construction',
      component: Construction,
      meta:{
       
        crypto:true,
        beta:false,
      }
    },
    {
      path: '/disclaimer',
      name: 'Disclaimer',
      component: Disclaimer,
    },
  
   
    {
      path: '/bitmexLtcUsd',
      name: 'BitmexLtcUsd',
      component: BitmexLtcUsd,
      meta:{
        bitmex:true,
        beta:false,
      }
    },
    {
      path: '/bitmexXrpUsd',
      name: 'BitmexXrpUsd',
      component: BitmexXrpUsd
    },
    {
      path: '/bitmexBchUsd',
      name: 'BitmexBchUsd',
      component: BitmexBchUsd,
      meta:{
        title:'BCHUSD BitMEX Calculator',
        bitmex:true,
        beta:false,
      }
    },
    {
      path: '/bitmexEthUs',
      name: 'BitmexEthUs',
      component: BitmexEthUs,
      meta:{
        title:'ETHUSD BitMEX Calculator',
        bitmex:true,
        beta:false,
      }
    },
    {
    path: '/journal',
    name: 'Journal',
    component: Journal,
    meta:{
      title:'CalcMEX Trading Journal'
    }
  },
    {
      path: '*',
      redirect: '/home',
     
    },
    {
      path: '/tweets',
      name: 'Tweets',
      component: Tweets,
      meta:{
        beta:false,
        crypto:true,
      }
    },
    {
      path: '/binance_calculator',
      name: 'Binance_calculator',
      component: Binance_calculator,
      meta:{
        beta:false,
        binance:true,
      }
    },
  ]
});
/*
router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next('xbt_calculator');
  else if (!requiresAuth && currentUser) next('home');
  else next();
});
*/
export default router;
