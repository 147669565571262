<template>
  <div class="charts">
    
  <GChart
    type="PieChart"
    :data= "chartData3"
    :options= "chartOptions3"/>

   <GChart
    type="PieChart"
    :data= "chartData3a"
    :options= "chartOptions3a"/>

    <GChart type="PieChart" :data= "chartData3b" :options= "chartOptions3b"/>
    
     <GChart
    type="LineChart"
    :data= "chartData22"
    :options= "chartOptions22"
  />
  </div>
</template>

<script>
 import firebase from 'firebase/app';
import VueGoogleCharts from 'vue-google-charts';
import Journal from '@/views/Journal';
  export default {
    name: 'Charts',
    data() {
      return {
       chartData1:
        [
        ['Year', 'Sales', 'Expenses', 'Profit'],
        ['2014', 1000, 400, 200],
        ['2015', 1170, 460, 250],
        ['2016', 660, 1120, 300],
        ['2017', 1030, 540, 350]
      ],    
        chartOptions1: {
        chart: {
          title: 'Company Performance',
          subtitle: 'Sales, Expenses, and Profit: 2014-2017',
        }
      },

       chartData:  [
          ['Director (Year)',  'Rotten Tomatoes', 'IMDB'],
          ['Alfred Hitchcock (1935)', 8.4,         7.9],
          ['Ralph Thomas (1959)',     6.9,         6.5],
          ['Don Sharp (1978)',        6.5,         6.4],
          ['James Hawes (2008)',      4.4,         6.2]
        ],
        chartOptions: {
          title: 'The decline of \'The 39 Steps\'',
          vAxis: {title: 'Accumulated Rating'},
          isStacked: true
        },

      chartData2:  [
          ['Year', 'Sales', 'Expenses'],
          ['2004',  1000,      400],
          ['2005',  1170,      460],
          ['2006',  660,       1120],
          ['2007',  1030,      540]
        ],
         chartOptions2:    {
          title: 'Company Performance',
          curveType: 'function',
          legend: { position: 'bottom' }
        },
 chartData22: [ 
 ['risk reward','date'],
      [ this.todos12a,this.todos12b]] ,

         chartOptions22:    {
          title: 'Company Performance',
          curveType: 'function',
          legend: { position: 'bottom' }
        },
      chartData3:[
          ['Side','Trade'],
          ['BUY',this.todos9aa],
          ['SELL',this.todos9ab],
       ],
          chartOptions3:   {
          title: 'Buy/Sell',
          is3D: true,
        },

         chartData3a:[
          ['Entry type','Trade'],
          ['Entry Maker',this.todos10aa],
          ['Entry Taker',this.todos10ab],  
       ],
          chartOptions3a:   {
          title: 'Entry Type',
          is3D: true,
        },

 chartData3b:[
          ['Exit type','Trade'],
          ['Exit Maker',this.todos11aa],
          ['Exit Taker',this.todos11ab],  
       ],
          chartOptions3b: {
          title: 'Exit Type',
          is3D: true,
        },

chartData4:[
          ['Label','Value'],
          ['Memory',80],
        ],
     chartOptions4:{
          width: 400, height: 120,
          redFrom: 90, redTo: 100,
          yellowFrom:75, yellowTo: 90,
          minorTicks: 5,
        },
 
      }
      
    },
     props: ['todos9aa','todos9ab','todos10aa','todos10ab','todos11aa','todos11ab', 'todos12','todos12a','todos12b']
    ,
     methods: { 
},}
</script>
<style>
.div{
  width: 100%;
}
.cam{
  width: 25%;
}

</style>

   
      <GChart
    type="ColumnChart"
    :data= " chartData"
    :options= "chartOptions"
  />
   <GChart
    type="SteppedAreaChart"
    :data= "chartData"
 :options= "chartOptions"/>

    <GChart type="Gauge" :data= "chartData4" :options= "chartOptions4"/>
   ['Year', 'Sales', 'Expenses'],
          ['2004',  1000,      400],
          ['2005',  1170,      460],
          ['2006',  660,       1120],
          ['2007',  1030,      540]