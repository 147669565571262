<template>
<div class="trade" >

  Eth Xbt Value {{quoteEth}} Bch Xbt Value{{quoteBch}} Bxbt{{quotebxbt}}
      <md-table style="overflow-x:auto;  background:  #585858;text-align:center " v-model= "trade" md-sort="date" md-sort-order="asc" md-card md-fixed-header>
              <md-table-toolbar>
                
                  <h1 class="md-title" style="color:burlywood;"><strong>ADD TRADE</strong></h1>
                                  
                  <md-button @click= "addJournal()" style="color:burlywood;">  <md-icon style="color:burlywood;">note_add</md-icon><md-tooltip md-direction="left">login necessary</md-tooltip> add</md-button>
              </md-table-toolbar>
                 <md-table-row  >
      <md-table-cell  style="width:6,92%" md-label="Date" md-sort-by="date" ><md-input  ></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Exchange" md-sort-by="exchange"><md-input   ></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Pair" md-sort-by="pair">
        <md-input   />
</md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Direction" md-sort-by="direction"  ><md-input  ></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Entry" md-sort-by="entry" ><md-input ></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Stop Loss" md-sort-by="stopLoss"><md-input type="number"  placeholder=Stop-Loss maxlength="8" ></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Target" md-sort-by="target" md-numeric><md-input type="number" placeholder=Target maxlength="8"></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Exit" md-sort-by="exitReal"  v-model= "trade.exitReal" ><md-input ></md-input></md-table-cell>
        <md-table-cell style="width:6,92%" md-label="Position Size" md-sort-by="positionSize"  ><md-input  ></md-input></md-table-cell>
        <md-table-cell style="width:6,92%" md-label="Maker Fees" md-sort-by=""><md-input  ></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="$ PnL" md-sort-by="result$" v-model= "trade.result$" > <md-input  ></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Xbt PnL" md-sort-by="resultXbt"  v-model= "trade.resultXbt" ><md-input   ></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Risk Reward" md-sort-by="riskReward"  v-model= "riskReward" ><md-input   ></md-input></md-table-cell>
        <md-table-cell style="width:10%" md-label="Comments" md-sort-by="comments"><md-input maxlength="300"  placeholder=Comments></md-input></md-table-cell>
    </md-table-row>  

      <md-table-row  >
      <md-table-cell  style="width:6,92%" md-label="Date" md-sort-by="date" ><md-input v-model= 'trade.date '  ></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Exchange" md-sort-by="exchange"><md-input  v-model= "trade.exchange" ></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Pair" md-sort-by="pair">
        <md-input type="search"  v-model= "trade.pair" list="data" placeholder=Pair  maxlength="8"/>
  <datalist  id="data">
      <option value="XBTUSD" />
      <option value="ETHUSD" />
      <option value="BCHUSD" />
        <option value="LTCUSD" />
      <option value="XRPUSD" />     
  </datalist>
</md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Direction" md-sort-by="direction" v-model= "trade.direction"  >{{direction1}}</md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Entry" md-sort-by="entry" >{{sumEntrySup3}}</md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Stop Loss" md-sort-by="stopLoss"><md-input type="number" v-model.number= "trade.stopLoss " placeholder=Stop-Loss maxlength="8" ></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Target" md-sort-by="target" md-numeric><md-input type="number" v-model.number= "trade.target" placeholder=Target maxlength="8"></md-input></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Exit" md-sort-by="exitReal"  v-model= "trade.exitReal" >{{exitRealMultiple}}</md-table-cell>
        <md-table-cell style="width:6,92%" md-label="Position Size" md-sort-by="positionSize"  >{{positionSizeMultiple}}</md-table-cell>
        <md-table-cell style="width:6,92%" md-label="Maker Fees" md-sort-by=""><md-switch  v-model.number= "trade.entryMaker"  >Entry Maker </md-switch><md-switch  v-model.number= "trade.exitMaker"  >Exit Maker </md-switch></md-table-cell>
      <md-table-cell style="width:6,92%" md-label="$ PnL" md-sort-by="result$" v-model= "trade.result$" > {{pnl}}</md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Xbt PnL" md-sort-by="resultXbt"  v-model= "trade.resultXbt" >{{pnlXbt}}</md-table-cell>
      <md-table-cell style="width:6,92%" md-label="Risk Reward" md-sort-by="riskReward"  v-model= "riskReward" >{{riskReward1}}</md-table-cell>
        <md-table-cell style="width:10%" md-label="Comments" md-sort-by="comments"><md-input maxlength="300" v-model= "trade.comments" placeholder=Comments></md-input></md-table-cell>
    </md-table-row>    


    <md-table-row  >
      <md-table-cell md-label="Date" md-sort-by="date"   >{{trade.date}}</md-table-cell>
      <md-table-cell md-label="Exchange" md-sort-by="exchange">{{trade.exchange}}</md-table-cell>
      <md-table-cell md-label="Pair" md-sort-by="pair">{{trade.pair}}</md-table-cell>
      <md-table-cell md-label="Direction" md-sort-by="direction">{{direction1}}</md-table-cell>
      <md-table-cell md-label="Entry" md-sort-by="entry"><md-input  type="number" v-model.number= "entry1 " maxlength="8" placeholder=Entry ></md-input></md-table-cell>
      <md-table-cell md-label="Stop Loss" md-sort-by="stopLoss">{{trade.stopLoss}}</md-table-cell>
      <md-table-cell md-label="Target" md-sort-by="target" md-numeric>{{trade.target}}</md-table-cell>
      <md-table-cell md-label="Exit" md-sort-by="exitReal"></md-table-cell>
        <md-table-cell md-label="Position Size" md-sort-by="positionSize"><md-input type="number" v-model.number= "posiSize1" placeholder=Position-Size maxlength="6"></md-input></md-table-cell>
        <md-table-cell md-label="Maker Fees" md-sort-by="">Entry Maker  {{ trade.entryMaker}}</md-table-cell>
      <md-table-cell md-label="$ PnL" md-sort-by="result$"></md-table-cell>
      <md-table-cell md-label="Xbt PnL" ></md-table-cell>
      <md-table-cell md-label="Risk Reward" ></md-table-cell>
        <md-table-cell md-label="Comments" md-sort-by="comments"><md-button v-on:click="row2 = !row2" style="color:burlywood;">add entry Row</md-button></md-table-cell>
    </md-table-row>
    <md-table-row v-if="row2" >
      <md-table-cell md-label="Date" md-sort-by="date"   >{{trade.date}}</md-table-cell>
      <md-table-cell md-label="Exchange" md-sort-by="exchange">{{trade.exchange}}</md-table-cell>
      <md-table-cell md-label="Pair" md-sort-by="pair">{{trade.pair}}</md-table-cell>
      <md-table-cell md-label="Direction" md-sort-by="direction">{{direction1}}</md-table-cell>
      <md-table-cell md-label="Entry" md-sort-by="entry"><md-input type="number" v-model.number= "entry2 " placeholder=Entry maxlength="8" ></md-input></md-table-cell>
      <md-table-cell md-label="Stop Loss" md-sort-by="stopLoss">{{trade.stopLoss}}</md-table-cell>
      <md-table-cell md-label="Target" md-sort-by="target" md-numeric>{{trade.target}}</md-table-cell>
      <md-table-cell md-label="Exit" md-sort-by="exitReal"></md-table-cell>
        <md-table-cell md-label="Position Size" md-sort-by="positionSize"><md-input type="number" v-model.number= "posiSize2" placeholder=Position-Size maxlength="6"></md-input></md-table-cell>
        <md-table-cell md-label="Maker Fees" md-sort-by="">Entry Maker  {{ trade.entryMaker}}</md-table-cell>
      <md-table-cell md-label="$ PnL" md-sort-by="result$"></md-table-cell>
      <md-table-cell md-label="Xbt PnL" md-sort-by="resultXbt"></md-table-cell>
      <md-table-cell md-label="Risk Reward" ></md-table-cell>
        <md-table-cell md-label="Comments" md-sort-by="comments"><md-button v-on:click="row3 = !row3" style="color:burlywood;">add entry Row</md-button></md-table-cell>
    </md-table-row>
    <md-table-row  v-if="row3">
      <md-table-cell md-label="Date" md-sort-by="date"   >{{trade.date}}</md-table-cell>
      <md-table-cell md-label="Exchange" md-sort-by="exchange">{{trade.exchange}}</md-table-cell>
      <md-table-cell md-label="Pair" md-sort-by="pair">{{trade.pair}}</md-table-cell>
      <md-table-cell md-label="Direction" md-sort-by="direction">{{direction1}}</md-table-cell>
      <md-table-cell md-label="Entry" md-sort-by="entry"><md-input type="number" v-model.number= "entry3 " placeholder=Entry maxlength="8"></md-input></md-table-cell>
      <md-table-cell md-label="Stop Loss" md-sort-by="stopLoss">{{trade.stopLoss}}</md-table-cell>
      <md-table-cell md-label="Target" md-sort-by="target" md-numeric>{{trade.target}}</md-table-cell>
      <md-table-cell md-label="Exit" md-sort-by="exitReal"></md-table-cell>
        <md-table-cell md-label="Position Size" md-sort-by="positionSize"><md-input type="number" v-model.number= "posiSize3" placeholder=Position-Size maxlength="6"></md-input></md-table-cell>
        <md-table-cell md-label="Maker Fees" md-sort-by="">Entry Maker  {{ trade.entryMaker}}</md-table-cell>
      <md-table-cell md-label="$ PnL" md-sort-by="result$"></md-table-cell>
      <md-table-cell md-label="Xbt PnL" md-sort-by="resultXbt"></md-table-cell>
      <md-table-cell md-label="Risk Reward" ></md-table-cell>
        <md-table-cell md-label="Comments" md-sort-by="comments"><md-button v-on:click="row4 = !row4" style="color:burlywood;">add entry Row</md-button></md-table-cell>
    </md-table-row>
    <md-table-row  v-if="row4" >
      <md-table-cell md-label="Date" md-sort-by="date"   >{{trade.date}}</md-table-cell>
      <md-table-cell md-label="Exchange" md-sort-by="exchange">{{trade.exchange}}</md-table-cell>
      <md-table-cell md-label="Pair" md-sort-by="pair">{{trade.pair}}</md-table-cell>
      <md-table-cell md-label="Direction" md-sort-by="direction">{{direction1}}</md-table-cell>
      <md-table-cell md-label="Entry" md-sort-by="entry"><md-input type="number" v-model.number= "entry4 " placeholder=Entry maxlength="8"></md-input></md-table-cell>
      <md-table-cell md-label="Stop Loss" md-sort-by="stopLoss">{{trade.stopLoss}}</md-table-cell>
      <md-table-cell md-label="Target" md-sort-by="target" md-numeric>{{trade.target}}</md-table-cell>
      <md-table-cell md-label="Exit" md-sort-by="exitReal"></md-table-cell>
        <md-table-cell md-label="Position Size" md-sort-by="positionSize"><md-input type="number" v-model.number= "posiSize4" placeholder=Position-Size maxlength="6"></md-input></md-table-cell>
        <md-table-cell md-label="Maker Fees" md-sort-by=""> Entry Maker  {{ trade.exitMaker}}</md-table-cell>
      <md-table-cell md-label="$ PnL" md-sort-by="result$"></md-table-cell>
      <md-table-cell md-label="Xbt PnL" md-sort-by="resultXbt"></md-table-cell>
      <md-table-cell md-label="Risk Reward" ></md-table-cell>
        <md-table-cell md-label="Comments" md-sort-by="comments"></md-table-cell>
    </md-table-row>


          
    <md-table-row   >
      <md-table-cell md-label="Date" md-sort-by="date"   >{{trade.date}}</md-table-cell>
      <md-table-cell md-label="Exchange" md-sort-by="exchange">{{trade.exchange}}</md-table-cell>
      <md-table-cell md-label="Pair" md-sort-by="pair">{{trade.pair}}</md-table-cell>
      <md-table-cell md-label="Direction" md-sort-by="direction">{{direction1}}</md-table-cell>
      <md-table-cell md-label="Entry" md-sort-by="entry"></md-table-cell>
      <md-table-cell md-label="Stop Loss" md-sort-by="stopLoss">{{trade.stopLoss}}</md-table-cell>
      <md-table-cell md-label="Target" md-sort-by="target" md-numeric>{{trade.target}}</md-table-cell>
      <md-table-cell md-label="Exit" md-sort-by="exitReal"><md-input  type="number" v-model.number= "exitReal1" maxlength="8"></md-input></md-table-cell>
        <md-table-cell md-label="Position Size" md-sort-by="positionSize"><md-input type="number" v-model.number= "posiSize1ex" placeholder=Position-Size maxlength="6"></md-input></md-table-cell>
        <md-table-cell md-label="Maker Fees" md-sort-by="">Exit Maker  {{ trade.exitMaker}}</md-table-cell>
      <md-table-cell md-label="$ PnL" md-sort-by="result$"></md-table-cell>
      <md-table-cell md-label="Xbt PnL" md-sort-by="resultXbt"></md-table-cell>
      <md-table-cell md-label="Risk Reward" ></md-table-cell>
        <md-table-cell md-label="Comments" md-sort-by="comments"><md-button v-on:click="row2ex = !row2ex" style="color:burlywood;">add exit Row</md-button></md-table-cell>
    </md-table-row>
    <md-table-row  v-if="row2ex" >
      <md-table-cell md-label="Date" md-sort-by="date"   >{{trade.date}}</md-table-cell>
      <md-table-cell md-label="Exchange" md-sort-by="exchange">{{trade.exchange}}</md-table-cell>
      <md-table-cell md-label="Pair" md-sort-by="pair">{{trade.pair}}</md-table-cell>
      <md-table-cell md-label="Direction" md-sort-by="direction">{{direction1}}</md-table-cell>
      <md-table-cell md-label="Entry" md-sort-by="entry"></md-table-cell>
      <md-table-cell md-label="Stop Loss" md-sort-by="stopLoss">{{trade.stopLoss}}</md-table-cell>
      <md-table-cell md-label="Target" md-sort-by="target" md-numeric>{{trade.target}}</md-table-cell>
      <md-table-cell md-label="Exit" md-sort-by="exitReal"><md-input  type="number" v-model.number= "exitReal2" maxlength="8"></md-input></md-table-cell>
        <md-table-cell md-label="Position Size" md-sort-by="positionSize"><md-input type="number" v-model.number= "posiSize2ex" placeholder=Position-Size maxlength="6"></md-input></md-table-cell>
        <md-table-cell md-label="Maker Fees" md-sort-by="">Exit Maker  {{ trade.exitMaker}}</md-table-cell>
      <md-table-cell md-label="$ PnL" md-sort-by="result$"></md-table-cell>
      <md-table-cell md-label="Xbt PnL" ></md-table-cell>
      <md-table-cell md-label="Risk Reward" ></md-table-cell>
        <md-table-cell md-label="Comments" md-sort-by="comments"><md-button v-on:click="row3ex = !row3ex" style="color:burlywood;">add exit Row</md-button></md-table-cell>
    </md-table-row>
    <md-table-row v-if="row3ex"  >
      <md-table-cell md-label="Date" md-sort-by="date"   >{{trade.date}}</md-table-cell>
      <md-table-cell md-label="Exchange" md-sort-by="exchange">{{trade.exchange}}</md-table-cell>
      <md-table-cell md-label="Pair" md-sort-by="pair">{{trade.pair}}</md-table-cell>
      <md-table-cell md-label="Direction" md-sort-by="direction">{{direction1}}</md-table-cell>
      <md-table-cell md-label="Entry" md-sort-by="entry"></md-table-cell>
      <md-table-cell md-label="Stop Loss" md-sort-by="stopLoss">{{trade.stopLoss}}</md-table-cell>
      <md-table-cell md-label="Target" md-sort-by="target" md-numeric>{{trade.target}}</md-table-cell>
      <md-table-cell md-label="Exit" md-sort-by="exitReal"><md-input  type="number" v-model.number= "exitReal3" maxlength="8"></md-input></md-table-cell>
        <md-table-cell md-label="Position Size" md-sort-by="positionSize"><md-input type="number" v-model.number= "posiSize3ex" placeholder=Position-Size maxlength="6"></md-input></md-table-cell>
        <md-table-cell md-label="Maker Fees" md-sort-by="">Exit Maker  {{ trade.exitMaker}}</md-table-cell>
      <md-table-cell md-label="$ PnL" md-sort-by="result$"></md-table-cell>
      <md-table-cell md-label="Xbt PnL" md-sort-by="resultXbt"></md-table-cell>
      <md-table-cell md-label="Risk Reward" ></md-table-cell>
        <md-table-cell md-label="Comments" md-sort-by="comments"><md-button v-on:click="row4ex = !row4ex" style="color:burlywood;">add exit Row</md-button></md-table-cell>
    </md-table-row>
    <md-table-row v-if="row4ex"  >
      <md-table-cell md-label="Date" md-sort-by="date"   >{{trade.date}}</md-table-cell>
      <md-table-cell md-label="Exchange" md-sort-by="exchange">{{trade.exchange}}</md-table-cell>
      <md-table-cell md-label="Pair" md-sort-by="pair">{{trade.pair}}</md-table-cell>
      <md-table-cell md-label="Direction" md-sort-by="direction">{{direction1}}</md-table-cell>
      <md-table-cell md-label="Entry" md-sort-by="entry"></md-table-cell>
      <md-table-cell md-label="Stop Loss" md-sort-by="stopLoss">{{trade.stopLoss}}</md-table-cell>
      <md-table-cell md-label="Target" md-sort-by="target" md-numeric>{{trade.target}}</md-table-cell>
      <md-table-cell md-label="Exit" md-sort-by="exitReal"><md-input  type="number" v-model.number= "exitReal4" maxlength="8"></md-input></md-table-cell>
        <md-table-cell md-label="Position Size" md-sort-by="positionSize"><md-input type="number" v-model.number= "posiSize4ex" placeholder=Position-Size maxlength="6"></md-input></md-table-cell>
        <md-table-cell md-label="Maker Fees" md-sort-by="">Exit Maker  {{ trade.exitMaker}}</md-table-cell>
      <md-table-cell md-label="$ PnL" md-sort-by="result$"></md-table-cell>
      <md-table-cell md-label="Xbt PnL" md-sort-by="resultXbt"></md-table-cell>
      <md-table-cell md-label="Risk Reward" ></md-table-cell>
        <md-table-cell md-label="Comments" md-sort-by="comments"></md-table-cell>
    </md-table-row>
      </md-table>
 

</div>

</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth'; 
import 'firebase/functions';
export default {
  name: 'Trade',
  data() {
    return {
row2:false,
row3:false,
row4:false,
row2ex:false,
row3ex:false,
row4ex:false,
entry1:0,
entry2:0,
entry3:0,
entry4:0,
posiSize1:'',
posiSize2:'',
posiSize3:'',
posiSize4:'',
posiSize4ex:'',posiSize3ex:'',posiSize2ex:'',posiSize1ex:'',
exitReal1:0,    exitReal2:0,   exitReal3:0,   exitReal4:0,   
trade1:{},
      trade: 
      { 
        date: new Date().toISOString().substr(2, 8),
        exchange: 'Bitmex',
        pair:'',
        entry: '',
        stopLoss:'',
        direction:'',
        exitReal:'',
        target:'',
        exitType:'',
        positionSize: '',

        entryMaker:false,
        exitMaker:false,
        result$:'',
        resultXbt:'',
        riskReward: '',
        comments: '',         
      },
    }
  },
  computed: {
       
direction1: function(){
if (this.trade.target>this.sumEntrySup3) return 'BUY'
else if (this.trade.target<this.sumEntrySup3) return 'SELL'
else return ''
},
positionSizeMultiple: function(){  return this.posiSize1+this.posiSize2+this.posiSize3+this.posiSize4},
positionSizeMultipleex: function(){  return this.posiSize1ex+this.posiSize2ex+this.posiSize3ex+this.posiSize4ex},
 sumEntrySup3: function(){    
  var entryMoy=(this.entry1*this.posiSize1)+ (this.entry2*this.posiSize2)+(this.entry3*this.posiSize3)+(this.entry4*this.posiSize4);
    return entryMoy/this.positionSizeMultiple
  },
 exitRealMultiple: function(){  
var entryMoy1=(this.exitReal1*this.posiSize1ex)+ (this.exitReal2*this.posiSize2ex)+(this.exitReal3*this.posiSize3ex)+(this.exitReal4*this.posiSize4ex);
    return entryMoy1/this.positionSizeMultiple
  },
  },
created: function() {
var bxbt=this,
  wsbxbt = new WebSocket("wss://www.bitmex.com/realtime?subscribe=instrument:.BXBT");
 wsbxbt.onmessage = function (event) {
bxbt.quotebxbt1=JSON.parse(event.data);
  for (var i in bxbt.quotebxbt1.data) {
    var x = bxbt.quotebxbt1.data[i];
  bxbt.quote3bxbt=JSON.stringify(x.markPrice);
  bxbt.quotebxbt=parseFloat( bxbt.quote3bxbt);
 
  }

  }
var eth=this,
wsethusd = new WebSocket("wss://www.bitmex.com/realtime?subscribe=quote:ETHUSD");
wsethusd.onmessage = function (event) {
eth.quoteethusd=JSON.parse(event.data);
for (var i in eth.quoteethusd.data) {
  var x= eth.quoteethusd.data[i];
eth.quote3ethusd=JSON.stringify(x.bidPrice);
eth.quoteEth=parseFloat(eth.quote3ethusd*0.000001);

}
}
var bch=this,
 wsbchusd = new WebSocket("wss://www.bitmex.com/realtime?subscribe=quote:BCHUSD");
 wsbchusd.onmessage = function (event) {
bch.quoteethusd=JSON.parse(event.data);
  for (var i in bch.quoteethusd.data) {
    var x= bch.quoteethusd.data[i];
  bch.quote3ethusd=JSON.stringify(x.bidPrice);
bch.quoteBch=parseFloat(bch.quote3ethusd*0.000001);
  
  }

  };

  
},
    methods: { 
    /*   sumEntrySup3 (a,b,c,d,e,f,g,h,i){     
a=(a*b+c*d+e*f+g*h)
  //var entryMoy=(this.entry1*this.posiSize1)+ (this.entry2*this.posiSize2)+(this.entry3*this.posiSize3)+(this.entry4*this.posiSize4);
    return a/this.positionSizeMultiple
  },
  
  exitRealMultiple(a,b,c,d,e,f,g,h,i){  
    a=(a*b+c*d+e*f+g*h)  
  //var entryMoy1=(this.exitReal1*this.posiSize1ex)+ (this.exitReal2*this.posiSize2ex)+(this.exitReal3*this.posiSize3ex)+(this.exitReal4*this.posiSize4ex);
    return a/this.positionSizeMultiple
  },*/
        addJournal() {
       const addTrade= firebase.functions().httpsCallable('addTrade');
addTrade({    date: this.trade.date,
        exchange: this.trade.exchange,
        pair:this.trade.pair,
        entry: this.sumEntrySup3,
        stopLoss:this.trade.stopLoss,
        direction:this.direction1,
            exitReal:this.exitRealMultiple,
            target:this.trade.target,
            
            positionSize: this.positionSizeMultiple,
            positionSizeMultipleex:this.positionSizeMultipleex,
            entryMaker:this.trade.entryMaker,
            exitMaker:this.trade.exitMaker,
            quoteBch:this.quoteBch,
            quoteEth:this.quoteEth,
            quotebxbt:this.quotebxbt,
           // result$:this.result$,
              //resultXbt:this.resultXbt,
             // riskReward: this.riskReward,
                comments: this.comments}).then(result=>{
                //  alert(result.data)
                this.trade.result$=result.data[0].toFixed(2);
                this.trade.resultXbt=result.data[1];
                this.trade.riskReward=result.data[2].toFixed(2);
                this.trade.entry= this.sumEntrySup3,
                this.trade.direction=this.direction1,
                this.trade.exitReal=this.exitRealMultiple,
                this.trade.positionSize= this.positionSizeMultiple,
                   firebase
            .firestore()
            .collection("users")
            .doc(firebase.auth().currentUser.uid)
            .collection("tradeData")
            .add({
                trade: this.trade,
                createdAt: new Date(),
                
            }) 
})
}
   


 /*         addJournal1() {
      this.trade.direction=this.direction1;  
      this.trade.entry=this.sumEntrySup3;
      this.trade.exitReal=this.exitRealMultiple;
this.trade.positionSize=this.positionSizeMultiple;
this.trade.result$=this.pnl;
this.trade.resultXbt=this.pnlXbt;
this.trade.riskReward=this.riskReward1;
  firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .collection("tradeData")
      .add({
          trade: this.trade,
          createdAt: new Date(),
          
      })     
},*/


  }
}
</script>

<style lang="scss" scoped >

.div {
width: auto;

}
.md-table{

color:burlywood;
border-radius: 10px 10px 10px 10px;
}

.md-table-toolbar{

  color:burlywood;
  
}

.md-table-row{
  text-align: center;
    width: 100%;
}

  

.md-table-cell {
  background: #2f2f2f;
  color:burlywood;
  overflow:hidden;
//  width: 6.66%;
//  max-width:100px;
  white-space:wrap; 
overflow:visible;
text-overflow:ellipsis;



  
}
.md-input{
width: 100%;
background: #6f6f6f;
color: black;
  border-radius: 10px 10px 10px 10px;

      -moz-appearance: textfield;
      /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
}


.md-list{
background: burlywood;
}
/*
.multipleInput{

display: flex;
background: burlywood;
  width:auto;
}
*/
</style>
/*  var entrySup=[];
      entrySup.push(this.entry1);
    entrySup.push(this.entry2);
    entrySup.push(this.entry3);
    entrySup.push(this.entry4);
  var sumEntry= this.entry1+this.entry2+this.entry3+this.entry4 ;
    var entrySup2=(entrySup.filter(this.checkNum).length);              
checkNum(num){ if(num>0) return num}, */