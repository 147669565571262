<template>
 <div class="page-container">

<md-app >
      <md-app-toolbar class="  ">
              <md-button  class="md-icon-button" @click="toggleMenu "  v-if = "!menuVisible">
                <md-icon style="color: burlywood">menu</md-icon>
              </md-button>
              <div  v-if="$route.meta.beta " >  <img alt="calcmex calculator beta" src="./assets/beta.png">      </div>
               <div ><router-link  to="/bitmex-calculator"><img alt="calcmex bitmex calculator" src="./assets/logotitre.png"></router-link></div>
              <div ><router-link  to="/bitmex-calculator"><img alt="calcmex bitmex calculator" src="./assets/crypto-calculator.png"></router-link></div>
              
              <h1 v-if="$route.meta.bitmex" style="color: #cf974f">The Bitmex Calculator! Updated: Maker Fee -0.01% / Taker Fee 0.05% </h1>
              <h1 v-if="$route.meta.binance " style="color: #cf974f">The Binance Calculator!</h1>
              <h1 v-if="$route.meta.crypto " style="color: #cf974f">The CryptoTrading Position Size Calculator!</h1>
               
                <div class="md-toolbar-section-end">
           <md-button v-if="$route.meta.logout " class="md-accent md-bottom-left"  @click="logout">Logout
            </md-button>
          </div>
      </md-app-toolbar >
 <md-app-drawer   :md-active.sync= "menuVisible" md-swipeable="true"  >
      
        <md-toolbar style="color:burlywood" class="md-transparent " md-elevation="24" > 

<div class="md-toolbar-section-end">
            <md-button class="md-icon-button  "  @click="toggleMenu">
              <md-icon style="color: burlywood">keyboard_arrow_left</md-icon>
            </md-button>
</div>
        </md-toolbar>

        <md-list  :md-expand-single= "expandSingle"> 
          <md-list-item  > <md-icon style="color: burlywood">home</md-icon> <span  class="md-list-item-text" @click="toggleMenu" > <router-link  to="/bitmex-calculator">  <strong style="color: burlywood">HOME</strong></router-link></span>

   </md-list-item>
  <md-list-item  > <md-icon style="color: burlywood">trending_up</md-icon> <span  class="md-list-item-text" @click="toggleMenu" > <router-link  to="/binance_calculator">  <strong style="color: burlywood">BINANCE</strong></router-link></span>

   </md-list-item>

          <md-list-item md-expand style="text-align:left"><md-icon style="color: burlywood">trending_up</md-icon><strong>BitMEX</strong>
                      <md-list  slot="md-expand">               
                        <md-list-item  class="md-inset" >  <span  class="md-list-item-text" @click="toggleMenu" > <router-link  to="/home"><strong>XBTUSD</strong></router-link></span>           
                        </md-list-item>
                         <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/bitmexBchusd"><strong >BCHUSD</strong></router-link></span></md-list-item>
                          <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/bitmexEthUs"><strong>ETHUSD</strong></router-link></span></md-list-item>
                           <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">LTCUSD</router-link></span></md-list-item>
                            <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">XRPUSD</router-link></span></md-list-item>
                      </md-list>
          </md-list-item>
 <md-list-item  > <md-icon style="color: burlywood">login</md-icon>   <span  class="md-list-item-text" @click="toggleMenu" > <router-link  to="/login"><strong>LOGIN</strong></router-link></span></md-list-item>
 <md-list-item  > <md-icon style="color: burlywood">menu_book</md-icon> <span  class="md-list-item-text" @click="toggleMenu" > <router-link  to="/instructions">  <strong style="color: burlywood">INSTRUCTIONS</strong></router-link></span>
   </md-list-item>
    <md-list-item  > <md-icon style="color: burlywood">menu_book</md-icon> <span  class="md-list-item-text" @click="toggleMenu" > <router-link  to="/tweets">  <strong style="color: burlywood">TWEETS</strong></router-link></span>
   </md-list-item>
   
   <md-list-item  > <md-icon style="color: burlywood">contact_support</md-icon> <span  class="md-list-item-text" @click="toggleMenu" > <a href="https://twitter.com/calcmex?ref_src=twsrc%5Etfw" target="_blank">  <strong style="color: burlywood">FOLLOW/ SUGGEST</strong></a></span>
   </md-list-item>


        </md-list>
      </md-app-drawer>
     

    <md-app-content id="app">
  
  
    <router-view/>
  

 </md-app-content>
    </md-app>    
 </div>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/auth';

 export default {
    name: 'page-container',
    data() {
 return {
         menuVisible: false,
         expandNews: false,
        expandSingle: false,
      
      }
    },



   methods: {
      toggleMenu () {
        this.menuVisible = !this.menuVisible
      },
        logout: function() {
      firebase.auth().signOut().then(() => {
        this.$router.replace('xbt_calculator')
      })
    },
    
    }
 }
 </script>

<style lang="scss">
 /*app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: burlywood;
  
  background-color: rgb(32, 32, 32);
  background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,1)), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 0px 0px, 5px 5px, 0px 0px;
}*/

#app {
 
  text-align: center;
  color: #deb887;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.md-content {
  background-color: rgb(32, 32, 32);
  background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,1)), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 0px 0px, 5px 5px, 0px 0px;
color: burlywood;
 text-align: center;
 width:auto;
  overflow-x: hidden;
margin-top:1%;
}
#yo {
  font-size:xx-large;
  color: burlywood;
}
.md-app {
   
    border: 0px solid rgba(#000, .12);
    background-color: rgb(32, 32, 32);
  background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,1)), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
 background-position: 0px 0px, 0px 0px, 5px 5px, 0px 0px;
  }
.md-app-toolbar {

  background-color: rgb(32, 32, 32);
  background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,1)), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 0px 0px, 5px 5px, 0px 0px;
}

  .md-drawer {
    
 background-color: rgb(32, 32, 32);
  background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,1)), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 0px 0px,  0px 0px;
 
  }
  .md-app-drawer {
    
     -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  :-webkit-scrollbar {
  display: none;
}
  }
.md-inset {
  margin-left: 15%;
  
}

  .md-list {
       background-color: rgb(32, 32, 32);
  background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,1)), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 0px 0px,  0px 0px;
    width: auto; 
    color: burlywood;
   
  }

.md-list-item-text {
color: burlywood;
}
strong{
  color: burlywood
}
</style>

/* <div><img alt="bitmex" src="./assets/logo-bitmex.png"></div>
 <md-list-item>
            <md-list-item md-expand :md-expanded.sync="expandNews">
          <div><img  alt="deribit" src="./assets/logo-deribit.png"></div>
            <md-list   slot="md-expand">
                        <md-list-item  class="md-inset" >  <span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">XBTUSD</router-link></span>
                        
                        </md-list-item>
                         <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">BCHUSD</router-link></span></md-list-item>
                          <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">ETHUSD</router-link></span></md-list-item>
                           <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">LTCUSD</router-link></span></md-list-item>
                            <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">XRPUSD</router-link></span></md-list-item>
                      </md-list>
          </md-list-item>
           </md-list-item>

          <md-list-item>
    <md-list-item md-expand :md-expanded.sync="expandNews">
            <div><img alt="bybit" src="./assets/logo-bybit.png"></div>
            <md-list   slot="md-expand">
                        <md-list-item  class="md-inset" >  <span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">XBTUSD</router-link></span>
                        
                        </md-list-item>
                         <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">BCHUSD</router-link></span></md-list-item>
                          <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">ETHUSD</router-link></span></md-list-item>
                           <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">LTCUSD</router-link></span></md-list-item>
                            <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">XRPUSD</router-link></span></md-list-item>
                      </md-list>
          </md-list-item>
          </md-list-item>

          <md-list-item>
               <md-list-item md-expand :md-expanded.sync="expandNews">
           <div><img alt="huobi" src="./assets/logo-huobi.png"></div>
            <md-list   slot="md-expand">
                        <md-list-item  class="md-inset" >  <span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">XBTUSD</router-link></span>
                        
                        </md-list-item>
                         <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">BCHUSD</router-link></span></md-list-item>
                          <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">ETHUSD</router-link></span></md-list-item>
                           <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">LTCUSD</router-link></span></md-list-item>
                            <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">XRPUSD</router-link></span></md-list-item>
                      </md-list>
          </md-list-item>
          </md-list-item>

     <md-list-item>
               <md-list-item md-expand :md-expanded.sync="expandNews">
           ><div><img alt="binance" src="./assets/logo-binance.png"></div>
            <md-list   slot="md-expand">
                        <md-list-item  class="md-inset" >  <span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">XBTUSD</router-link></span>
                        
                        </md-list-item>
                         <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">BCHUSD</router-link></span></md-list-item>
                          <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">ETHUSD</router-link></span></md-list-item>
                           <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">LTCUSD</router-link></span></md-list-item>
                            <md-list-item  class="md-inset" ><span  class="md-list-item-text" @click="toggleMenu"> <router-link  to="/construction">XRPUSD</router-link></span></md-list-item>
                      </md-list>
          </md-list-item>
          </md-list-item>
          
          
          
           <md-list-item  > <md-icon style="color: burlywood">table_view</md-icon> <span  class="md-list-item-text" @click="toggleMenu" > <router-link  to="/journal">  <strong style="color: burlywood">TRADING JOURNAL</strong></router-link></span>

   </md-list-item>*/