<template>
  <div class="home">
   
       <BitmexBchUs   />
    
   
     


  
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';

// @ is an alias to /src
import App from '@/App.vue'
import BitmexBchUs from '@/components/BitmexBchUs.vue'

export default {
  name: 'bitmexBchUsd',
  components: {
    BitmexBchUs,
  },
   metaInfo: {
    title: " BCH calculator fees position money-management trading anti liquidation Bitmex",  
     link: [
    {rel: 'canonical', href: 'https://calcmex.com/bitmexBchUsd'}
  ],
      meta: [
          
      { name: "description", content: "Calcul the maximal Bitmex BCH trading position size." },
      { name: "keywords", content: "BCH, trading, bitmex, calculator, money-management,anti liquidation" },   
    
  
  
    // OpenGraph data (Most widely used)
    {property: 'og:title', content: 'CalcMEX Bitmex BCH calculator fees position money-management trading anti liquidation'},
    {property: 'og:site_name', content: 'CalcMEX'},
    // The list of types is available here: http://ogp.me/#types
    {property: 'og:type', content: 'website'},
    // Should the the same as your canonical link, see below.
    {property: 'og:url', content: 'https://calcmex.com/#/bitmexBchUsd'},
    {property: 'og:image', content: 'https://calcmex.com/img/CalcMEX%20Logo%20titre.4e0866d1.png'},
    // Often the same as your meta description, but not always.
    {property: 'og:description', content: 'Calcul the maximal cryptocurrency bitmex trading position, fees inclued, using our money-management BitMEX calculator anti liquidation'},

    // Twitter card
    {name: 'twitter:card', content: 'summary'},
    {name: 'twitter:site', content: 'https://calcmex.com'},
    {name: 'twitter:title', content: 'CalcMEX App'},
    {name: 'twitter:description', content: 'Calcul the maximal Bitmex trading position size.'},
    // Your twitter handle, if you have one.
    {name: 'twitter:creator', content: '@CalcMex'},
    {name: 'twitter:image:src', content: 'https://calcmex.com/img/CalcMEX%20Logo%20titre.4e0866d1.png'},

    // Google / Schema.org markup:
    {itemprop: 'name', content: 'CalcMEX App'},
    {itemprop: 'description', content: 'CalcMEX BCH integrates technicals parameters and calculates the maximal position size to trade so that it does not exceed the percentage of risk fixed (including fees) anti liquidation'},
    {itemprop: 'image', content: 'https://calcmex.com/img/CalcMEX%20Logo%20titre.4e0866d1.png'},
  
      ]
  },
  methods: {
    logout: function() {
      firebase.auth().signOut().then(() => {
        this.$router.replace('xbt_calculator')
      })
    },
    }
    }
</script>

<style scoped>
.tweet {
  margin-top:1%;
}
.md-button {
   
  border-radius: 10%;
}
</style>
