
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebaseui';
import Vue from 'vue';
import App from './App.vue';


import router from './router';
//import store from './store'


Vue.config.productionTip = false;

let app='';



var firebaseConfig = 
{ apiKey: "AIzaSyC8RqVIBYpl_sfD7BKS9OKkw6HI4rz_P8k",
  authDomain: "test-functioncalc.firebaseapp.com",
  databaseURL: "https://test-functioncalc.firebaseio.com",
  projectId: "test-functioncalc",
  storageBucket: "test-functioncalc.appspot.com",
  messagingSenderId: "445569544771",
  appId: "1:445569544771:web:3b33c118b8af6e5b64daf6",
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  firebase.auth().onAuthStateChanged(() => {
    if (!app) {
    //  eslint-disable no-new 
      app = new Vue({
     
        router,
        render: h => h(App)
      }).$mount('#app');
    }
  });
