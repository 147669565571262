<template>
 

 <div class="bitmex-calculator">



      <div class="md-layout  md-gutter   " >
<div class="md-layout-item md-size-10 md-medium-size-5 md-small-size-5 md-xsmall-size-3 " ></div>
        
            <div class="md-layout-item md-size-50 md-medium-size-90 md-small-size-90 md-xsmall-94 " >
                  
<div class= "text ">
  <br>

CalcMEX App integrates the technical parameters and <strong>calculates the highest trading position size (including Maker or Taker fees)</strong>  to ensure that the losing trades actually match at the desired level of risk. <br><br>
The application calculates the<strong> maximum position </strong>(authorised by the risk %)  considering the<strong> leverage effect allocated by Bitmex or Binance</strong>  ! 👊<br><br>
CalcMEX App returns the expected results of professional traders= <strong>the position size, the leverage, the riskreward, the profit, the breakeven, the loss.</strong> 👌. <br><br>
This is the essential tool for measuring accurately (<strong>including costs</strong>) trade viability in<strong> money management</strong> terms ! 🎯<br><br>

<br><br>
<router-link to="/instructions" style="color:#ab885a;">How to use the Bitmex Anti-Liquidation Tool</router-link>
</div>
             
 </div>
  <div class="md-layout-item   md-gutter md-size-30 md-medium-size-90 md-small-size-100 md-xsmall-94">
      <div class="md-layout-item  " >
        <iframe width="560" height="315" src="https://www.youtube.com/embed/6tHZXglB1Gc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      </div><br> <div class="md-layout-item  " ><iframe width="560" height="315" src="https://www.youtube.com/embed/d_2xY7dEoAI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div><br><br>
     <div><h1><a style="color:#ab885a;" href="https://medium.com/calcmex/calcul-your-bitmex-trading-position-size-fees-inclued-for-a-correct-money-management-edf1c9aba896?sk=5572674f0381740dbe2ff789a49b8492"> Calcul your Bitmex trading position size fees inclued for a correct money management</a></h1></div>
 
     
<div class="md-layout-item  " >
        
  </div>
  </div>
    <div class="md-layout-item md-size-10 md-medium-size-5 md-small-size-5 md-xsmall-size-3" ></div>
                  </div><br><br>
          

          <a href="https://twitter.com/calcmex?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false" style="color:#ab885a;">Follow @calcmex on twitter for the latest events</a>
   
 <div class="g-ytsubscribe" data-channelid="UCvS-szGErp9fsA0-ZpG_-Ow" data-layout="default" data-theme="dark" data-count="hidden"></div>
          
           
      </div>
 
</template>

<script>
  export default {
    name: 'bitmex-calculator',
    data() {
 return {
         
      }
    },
       
    
    methods: {
    },
     metaInfo: {
    title: "Bitmex and Binance Correct Calculator Position Size (fees included)  THE money-management trading tool anti liquidation",  
     link: [
    {rel: 'canonical', href: 'https://calcmex.com/bitmex-calculator'}
  ],
      meta: [
          
      { name: "description", content: "Calcul the maximal correct Bitmex Binance trading position size (fees included)." },
      { name: "keywords", content: "trading, bitmex, binance, calculator, money-management" },   
    
  
  
    // OpenGraph data (Most widely used)
    {property: 'og:title', content: 'Bitmex Binance Correct Calculator Position Size (fees included)  THE money-management trading tool anti liquidation'},
    {property: 'og:site_name', content: 'CalcMEX'},
    // The list of types is available here: http://ogp.me/#types
    {property: 'og:type', content: 'website'},
    // Should the the same as your canonical link, see below.
    {property: 'og:url', content: 'https://calcmex.com/bitmex-calculator'},
    {property: 'og:image', content: 'https://calcmex.com/img/CalcMEX%20Logo%20titre.4e0866d1.png'},
    // Often the same as your meta description, but not always.
    {property: 'og:description', content: 'Calcul the maximal cryptocurrency bitmex binance trading position, fees inclued, using our free money-management BitMEX calculator'},

    // Twitter card
    {name: 'twitter:card', content: 'summary'},
    {name: 'twitter:site', content: 'https://calcmex.com/bitmex-calculator'},
    {name: 'twitter:title', content: 'CalcMEX App'},
    {name: 'twitter:description', content: 'Calcul the maximal Bitmex Binance trading position size.'},
    // Your twitter handle, if you have one.
    {name: 'twitter:creator', content: '@CalcMex'},
    {name: 'twitter:image:src', content: 'https://calcmex.com/img/CalcMEX%20Logo%20titre.4e0866d1.png'},

    // Google / Schema.org markup:
    {itemprop: 'name', content: 'CalcMEX App'},
    {itemprop: 'description', content: 'CalcMEX App integrates technicals parameters and calculates the maximal position size to trade so that it does not exceed the percentage of risk fixed (including fees)'},
    {itemprop: 'image', content: 'https://calcmex.com/img/CalcMEX%20Logo%20titre.4e0866d1.png'},
  
      ]
  }
  }
</script>

<style scoped>  /* "scoped" attribute limit the CSS to this component only */


.md-layout-item {
line-height: 1.6;
 letter-spacing: 1px;
  margin-top:0%;
  
}
  address{
    margin-top: 10%;
  }
 .text { 
  /* overflow-y:scroll;*/
   line-height: 1.6;

     font-size:22px;
     text-align: justify;
     color:burlywood;
    margin-right: 2%;
 }
  p {
    margin-top: 2%;
    font-size: 18px;
  color: burlywood;
  }
  p a {
color:#1ab7ea;
    text-decoration: underline;
    cursor: pointer;
  }
 
iframe {
  margin-top: 5%;
}

</style>

/*<h2>You already have an account? <br> <router-link  to="/login" style="color:#ab885a;">sign-in</router-link> <br>
    You don't have an account ?<br> You can <router-link to="/sign-up" style="color:#ab885a;">create one </router-link></h2><br>*/
