<template>
  <div class="bitmexLtcUs ">
   
  


      <div class="md-layout md-alignement-center-center " >


            <div class="md-layout-item md-size-30 md-medium-size-100 md-small-size-100 md-xsmall-100"   >

<div class="md-layout  " >
<div class="md-layout-item md-size-50 md-small-size-100 md-xsmall-100" >
                  <md-card class="y"  md-text-scrim  >
                          <md-card-header>
                            <div class="md-title ">Input</div>
                            <div class="md-subheading">Money managements &<br> Technicals parameters</div>
                          </md-card-header>
                          <div class="md-layout md-alignement-center-center " >
                  <div class="md-layout-item md-size-hide md-medium-size-hide md-small-size-hide md-xsmall-hide" ></div>
                  <div class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-100" >
                          <md-card-content >                 
                                    <md-list id="ze" class="md-double-line">
     
                                              <md-list-item>
                                                <div class="md-list-item-text  " >
                                              <span >Trading Budget</span> 
                                             

                                              <div id="budget"   >
                                               <md-field >
                                 
                                    <label>Xbt</label><md-tooltip md-direction="top">Press "Enter"</md-tooltip>
                                    <md-input v-model.number ='tradingBudgetXbt' type="number"  step="any"  v-on:keyup.enter="focusXbt" >{{ }}</md-input>
                                                  </md-field>
                                    
                                   <md-field >
                                    <label>$</label>
                                    <md-input  v-model.number='tradingBudget ' type="number" min="1" step="any" v-on:keyup.enter="focus$" >{{ }}</md-input>
                                  </md-field>
                                              </div>
                                                </div>
                                              </md-list-item>

                                              <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >Risk</span> 
                                                  <md-field    >  <label>%</label>
                                    <md-input ref="risk" min="1"  v-model="risk" type="number" step="any" v-on:keyup.enter="focusRisk"></md-input> </md-field> 
                                                  
                                                </div>
                                              </md-list-item>

                                              <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >Entry</span> 
                                                  <md-field   >  <label>$</label>
                                    <md-input ref="entry" v-model="entry" type="number" step="0.01" v-on:keyup.enter="focusEntry"></md-input> </md-field> 
                                                </div>
                                              </md-list-item>
                                          <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >Stop</span> 
                                                  <md-field  >  <label>$</label>
                                    <md-input ref="stop" v-model="Stop" type="number" step="0.01"  v-on:keyup.enter="focusStop"></md-input> </md-field> 
                                                  
                                                </div>
                                              </md-list-item>

                                              <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >Target</span> 
                                                  <md-field   >  <label>$</label>
                                    <md-input ref="target" v-model="target" type="number" step="0.01"  v-on:keyup.enter="submit"></md-input>
                                                    </md-field> 
                                                  
                                                </div>
                                              </md-list-item>

                                              <md-list-item>
                                                <div class="md-list-item-text  ">
                                                <span >Fees Maker</span>
                                                <md-field   >
                                                  <span class="fees">Entry</span>
                                                <md-switch class="md-primary  md-subheading" id=entryType  v-model="checkBox1">entry </md-switch> 
                                                <span  class="fees">    Exit</span>
                                  <md-switch class="md-primary md-subheading" id=exitType  v-model="checkBox2">Exit</md-switch>
                                  

                                                </md-field>
                                                </div>
                                  </md-list-item>
                                               </md-list>  

                                          
                               

                                
                          </md-card-content>
                           <md-card-actions md-alignment="right">
                             <md-chip class="md-raised md-primary" @click="submit" md-clickable>LOAD</md-chip>
                           </md-card-actions>
                          </div>
                  <div class="md-layout-item md-size-hide md-medium-size-hide md-small-size-hide md-xsmall-hide" ></div>
                  </div>
               </md-card>
</div>
      
                <div class="md-layout-item md-size-50 md-small-size-100 md-xsmall-100" >
                  <md-card class= " y "   md-text-scrim>  
                  <md-card-header >
                    <div class="md-title" >Output</div>
                     <div class="md-subheading">Tradings expectations </div>
                    
                  </md-card-header>
                 
                                    <md-card-content  >  
                                      
                          <md-tabs class="md-transparent" md-alignment="centered" md-swipeable= true>            
      <md-tab class="tab1"  md-label="$">
      
                                       <md-list  id="ze" class="md-double-line">
                                           <md-list-item >
                                             <div class="md-list-item-text  ">
                                                 <md-field  class='side' >
      <div class='sideBuy' v-show="sideBuy" >BUY</div> 
      <div class='sideSell' v-show="sideSell" >SELL</div> </md-field> 
                                             </div>
      </md-list-item>
                                              <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >Position Size</span> 
                                                  <md-field   ><span class='output' >{{trading}}</span>  </md-field> 
                                                 
                                                  <md-field   ><span class='output' id="trading$$" ></span>  </md-field> 
                                                </div>
                                              </md-list-item>

                                              <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >Leverage</span> 
                                                  <md-field    > <span class='output' >{{leverage}}</span> </md-field> 
                                                  
                                                </div>
                                              </md-list-item>

                                              <md-list-item > 
                                                <div   class="md-list-item-text  ">
                                              <span  >RiskReward</span> 
                                                  <md-field  ><span   v-bind:class ="(rr1<1)? 'red-color':'output'" >{{rr}}</span>  </md-field> 
                                                </div>
                                              </md-list-item>
                                          <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >Profit</span> 
                                                  <md-field   ><span class='output' id="profit"></span>  </md-field> 
                                                  
                                                </div>
                                              </md-list-item>

                                              <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >BreakEven</span> 
                                                  <md-field   > <span class='output' >{{breakeven}}</span>
                                                    </md-field> 
                                                  
                                                </div>
                                              </md-list-item>

                                              <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >Loss</span> 
                                                  <md-field ><span class='output' id="loss" ></span>  </md-field> 
                                                </div>
                                              </md-list-item>
                                           
                                       </md-list>   
 
     
      </md-tab>                         
                                       
<md-tab  md-label="Xbt">
 <md-list  id="ze" class="md-double-line">
                                           <md-list-item >
                                             <div class="md-list-item-text  ">
                                                 <md-field  class='side' >
      <div class='sideBuy' v-show="sideBuy" >BUY</div> 
      <div class='sideSell' v-show="sideSell" >SELL</div> </md-field> 
                                             </div>
      </md-list-item>
                                              <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >Position Size</span> 
                                                  <md-field   ><span class='output' id="trading" >{{trading}}</span>  </md-field> 
                                                  <md-field   ><span class='output' id="tradingXb" ></span>  </md-field> 
                                                 
                                                </div>
                                              </md-list-item>

                                              <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >Leverage</span> 
                                                  <md-field    > <span class='output'  >{{leverage}}</span> </md-field> 
                                                  
                                                </div>
                                              </md-list-item>

                                              <md-list-item > 
                                                <div   class="md-list-item-text  ">
                                              <span  >RiskReward</span> 
                                                  <md-field  ><span   v-bind:class ="(rr1<1)? 'red-color':'output'" >{{rr}}</span>  </md-field> 
                                                </div>
                                              </md-list-item>
                                          <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >Profit</span> 
                                                  <md-field   ><span class='output' id="profitxbt"></span>  </md-field> 
                                                  
                                                </div>
                                              </md-list-item>

                                              <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >BreakEven</span> 
                                                  <md-field   > <span class='output' >{{breakeven}}</span>
                                                    </md-field> 
                                                  
                                                </div>
                                              </md-list-item>

                                              <md-list-item>
                                                <div class="md-list-item-text  ">
                                              <span >Loss</span> 
                                                  <md-field ><span class='output' id="lossxbt" ></span>  </md-field> 
                                                </div>
                                              </md-list-item>
                                       
                                            

  </md-list> 
</md-tab>

   </md-tabs>
                                    
                                    </md-card-content>
                                       <md-card-expand>
        <md-card-actions md-alignment="right">
          <div>
          

          <md-card-expand-trigger >
            <md-button  class="md-icon-button" >
              <md-icon >keyboard_arrow_down</md-icon>
            </md-button>
          </md-card-expand-trigger>
          </div>
        </md-card-actions>

        <md-card-expand-content>
          <md-card-content>
           <span> Trading loss $: {{Loss}}</span><br>
             <span> Entry fees $: {{feesEntry}}</span><br>
               <span> Exit fees $: {{feesExit}}</span><br>
                 <span> Total loss $: {{feesEntry+feesExit-(Loss)}}</span>
          </md-card-content>
        </md-card-expand-content>
      </md-card-expand>
                                 
 </md-card> 

                  </div>
                 
                  </div>
                    
                </div>
                <div class="md-layout-item md-size-70  md-medium-size-hide md-small-size-hide md-xsmall-hide" >
                <VueTradingView  :options="{
                   width:'auto',
                    height:'800',
                  
                   
                  symbol: 'BITMEX:LTCUSD',
                  interval: '5',
                  timezone: 'Etc/UTC',
                  theme: 'dark',
                  style: '1',
                  locale: 'en',
                  
                  enable_publishing: false,
                  hide_side_toolbar: false,
                   show_popup_button: true,
  popup_width: '1000',
  popup_height: '650',
                    }" />

                   
            </div> 
             <div type="number" >XBTUSD{{quote3}}"    "
LTCUSD'' {{quoteEth}}'  '

BXBT''{{quotebxbt}}'   '
BLTCXBT''{{quotebeth}}'   '
Xbt Value''{{ xbtValue}}'   '
Usd Value''{{ usdValue}}'   '
Ltc Value''{{ethValue}}</div>
            </div>
           

            

      </div> 


</template>

<script>
import VueTradingView from 'vue-trading-view';
 import firebase from 'firebase/app';
import 'firebase/functions';
import './style.scss';

export default {
  name: 'BitmexLtcUs',
      data() {
        return{
          tradingBudgetXbt:'',
          tradingBudget:'',
            risk:'',
            entry:'',
            Stop:'',
            target:'',
            checkBox1:false,
            checkBox2:false,
        rr1:'', 
        quote3:'',
        quoteEth:'',
        quotebxbt:'',
        quotebeth:'',
        quoteBeth:'',
      xbtValue:'',
      ethValue:'',
      usdValue:'',
trading:'',
leverage:'',
rr:'',
breakeven:'',
      }
   }, 
 props: {
  msg:String,
 },
computed:{
sideBuy(){if (this.entry-this.Stop>0)
   return true;
 
  else return false},

sideSell(){if (this.entry-this.Stop<0)
   return true;
 
  else return false;}
},

 components: {
    VueTradingView,
   
  },
created: function() {

   var tee=this,
    ws= new WebSocket("wss://www.bitmex.com/realtime?subscribe=quote:XBTUSD").onmessage = function (event){
   tee.quote=JSON.parse(event.data);
                  for (var i in tee.quote.data) {
                  var x=tee.quote.data[i];
                tee.quote2=JSON.stringify(x.bidPrice);
                }
    tee.quote3=parseFloat(tee.quote2);
  }
  
var eth=this,
 wsethusd = new WebSocket("wss://www.bitmex.com/realtime?subscribe=quote:LTCUSD");
 wsethusd.onmessage = function (event) {
eth.quoteethusd=JSON.parse(event.data);
  for (var i in eth.quoteethusd.data) {
    var x= eth.quoteethusd.data[i];
  eth.quote3ethusd=JSON.stringify(x.bidPrice);
eth.quoteEth=parseFloat(eth.quote3ethusd);
  //console.log( eth.quoteethusd)
  }

  };
var bxbt=this,
  wsbxbt = new WebSocket("wss://www.bitmex.com/realtime?subscribe=instrument:.BXBT");
 wsbxbt.onmessage = function (event) {
bxbt.quotebxbt1=JSON.parse(event.data);
  for (var i in bxbt.quotebxbt1.data) {
    var x = bxbt.quotebxbt1.data[i];
  bxbt.quote3bxbt=JSON.stringify(x.markPrice);
  bxbt.quotebxbt=parseFloat( bxbt.quote3bxbt);
 
  }

  }
var beth=this,
     wsbethxbt = new WebSocket("wss://www.bitmex.com/realtime?subscribe=trade:.BLTCXBT");
 wsbethxbt.onmessage = function (event) {
beth.quotebeth1=JSON.parse(event.data);
  for (var i in beth.quotebeth1.data) {
    var x = beth.quotebeth1.data[i];
  beth.quote3bethxbt=JSON.stringify(x.price);
  beth.quotebeth=parseFloat(beth.quote3bethxbt);
 // console.log( beth.quotebeth1)
  }

  }
  

/*
var eth1=this,
 wseth1usd = new WebSocket("wss://www.bitmex.com/realtime?subscribe=instrument:.BETH");
 wseth1usd.onmessage = function (event) {
eth1.quoteethusd=JSON.parse(event.data);
  for (var i in eth1.quoteethusd.data) {
    var x= eth1.quoteethusd.data[i];
  eth1.quote3ethusd=JSON.stringify(x.markPrice);
eth1.quoteBeth1=parseFloat(eth1.quote3ethusd);
  //console.log( eth1.quoteBeth1)
  }
 }*/
  },
  methods: {

focusXbt: function(){
    this.tradingBudget=this.tradingBudgetXbt*this.quote3;
this.$refs.risk.$el.focus();
    this.submit();
},

focus$: function(){
  this.tradingBudgetXbt=this.tradingBudget/this.quote3;
 this.$refs.risk.$el.focus();
  this.submit();
},
focusRisk: function(){
  this.$refs.entry.$el.focus();
    
  this.submit();
},
focusEntry: function(){
  this.$refs.stop.$el.focus();
   
  this.submit();
  
},

focusStop: function(){
  this.$refs.target.$el.focus();
  
  this.submit();
  
},

 
xbtValuefct: function() {
this.xbtValue=parseFloat(this.quoteEth*0.000002);
} ,
usdValuefct: function(){
this.usdValue=parseFloat(this.xbtValue*this.quotebxbt);
},
ethValuefct:function(){
this.ethValue=parseFloat(this.xbtValue/this.quotebeth);
},
value: function(){
this.xbtValuefct(),
this.usdValuefct(),
this.ethValuefct()
},
    submit: function(){
      
     this.value();
  const sayHello1=firebase.functions().httpsCallable('ltcusd1');
//var tradingBudget= document.getElementById("tradingBudget").value;
//var risk= document.getElementById("risk").value;
//var entry= document.getElementById("entry").value;
//var Stop= document.getElementById("Stop").value;
//var target= document.getElementById("target").value;
//var checkBox1=document.getElementById("entryType").checked;
//var checkBox2=document.getElementById("exitType").checked;

sayHello1({tradingBudget: this.tradingBudget, risk: this.risk, entry:this.entry, Stop:this.Stop, target:this.target,checkBox1:this.checkBox1,checkBox2:this.checkBox2,usdValue:this.usdValue,xbtValue:this.xbtValue}).then(result=>{
   // console.log(result.data);
//document.getElementById("capital").innerHTML =result.data[0];//(Math.round(result.data * 100) / 100).toFixed(2);
//document.getElementById("capitalxbt").innerHTML =result.data[1];
//document.getElementById("risk").innerHTML =result.data[2];
//document.getElementById("entry").innerHTML =result.data[3];
//document.getElementById("entrytype").innerHTML =result.data[4];
//document.getElementById("stop").innerHTML =result.data[5];
//document.getElementById("exit").innerHTML =result.data[6];
//document.getElementById("exittype").innerHTML =result.data[7];
//document.getElementById("trading").innerHTML
 this.trading=((Math.round(result.data[8] * 100) / 100)/this.usdValue).toFixed(0)+"  Cs";
document.getElementById("tradingXb").innerHTML =((Math.round(result.data[8] * 100) / 100)/this.quotebxbt).toFixed(6)+"  Xbt";
document.getElementById("trading$$").innerHTML =((Math.round(result.data[8] * 100) / 100)).toFixed(0)+"  $";
//document.getElementById("leverage").innerHTML 
this.leverage=(Math.round(result.data[9] * 100) / 100).toFixed(2);
//document.getElementById("rr").innerHTML 
this.rr=(Math.round(result.data[10] * 100) / 100).toFixed(2);
//document.getElementById("breakeven").innerHTML
this.breakeven =(Math.round(result.data[11] * 100) / 100).toFixed(2)+"    $";
//result.data[11]+"    $";
document.getElementById("loss").innerHTML =(Math.round(result.data[12] * 100) / 100).toFixed(2)+"  $";
document.getElementById("lossxbt").innerHTML =((Math.round(result.data[12]*100)/100)/this.quotebxbt).toFixed(6)+"  Xbt";//(Math.round(result.data * 100) / 100).toFixed(2);
//document.getElementById("feesloss").innerHTML =result.data[14];
//document.getElementById("feeslossxbt").innerHTML =result.data[15];
document.getElementById("profit").innerHTML =(Math.round(result.data[16] * 100) / 100).toFixed(2)+"  $";
document.getElementById("profitxbt").innerHTML =((Math.round(result.data[16]*100)/100)/this.quotebxbt).toFixed(6)+"  Xbt";
this.feesEntry=result.data[18];
this.feesExit=result.data[19];
this.Loss=result.data[17];/*
document.getElementById("feesprofit").innerHTML =result.data[18];
document.getElementById("feesprofitxbt").innerHTML =result.data[19];
document.getElementById("feesentry").innerHTML =result.data[20];
document.getElementById("feesentryxbt").innerHTML =result.data[21];
document.getElementById("feesexitstop").innerHTML =result.data[22];
document.getElementById("feesexitstopxbt").innerHTML =result.data[23];
//document.getElementById("feesexittarget").innerHTML =result.data[24];
//document.getElementById("feesexittargetxbt").innerHTML =result.data[25];*/
 this.rr1=(Math.round(result.data[10] * 100) / 100).toFixed(2);
  
  
});
}
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.md-card-actions{
  background-color: rgb(32, 32, 32);
  background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,1)), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 0px 0px,  0px 0px;

}
.md-button{
  background: #6f6f6f;
}
.md-tabs{
  background: #2f2f2f;
  border-radius: 7px;
  
}
.md-tab{
   background: #2f2f2f;
    border-radius: 7px;
}

.isActive {
  display: none;
}
.red-color{
  color: red;
}
.side {
  text-align: center;
}
.sideBuy{
  color: whitesmoke;
   border: 3px outset #54bb74;
   border-radius: 7px;
   background: #54bb74;
   width: 70%;
   position: absolute;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
.sideSell{
  color: whitesmoke;
   border: 3px outset #e45e3a;
   border-radius: 7px;
   background: #e45e3a;
   width: 70%;
   position: absolute;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
#ze {
  //background:#9d9d9d;
  background: #2f2f2f;
}
.md-card-header {
  color:burlywood;
 
}
.md-card-content {
  color: burlywood;

}
.y {
    background-color: rgb(32, 32, 32);
  background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,1)), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 100% 100%, 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 0px 0px,  0px 0px;
  border-radius: 4%;
 //margin-top:3%;
 // background: #bdc3c7; /* fallback for old browsers */
 // background: -webkit-linear-gradient(to bottom, #444444, #2f2f2f); /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to bottom, #444444, #2f2f2f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
 .md-list {

   font-size-adjust: 0.6;
    border-radius: 4%;
   // background: #bdc3c7; /* fallback for old browsers */
  //background: -webkit-linear-gradient(to bottom, #444444, #2f2f2f); /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to bottom, #444444, #2f2f2f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
 }
.md-list-item {
  color:burlywood;

}
.md-field {
        /* Specilly used for Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
  border-radius: 7px;
     background: #6f6f6f;
 //background: #6f6f6f;
    

}

   span {
       text-align: center;
       color:burlywood;
   }
  .output {
    color: whitesmoke;
  }

.md-switch {
  color: whitesmoke;
    display: flex;
    flex-wrap: wrap;
}

.md-chip {
  font-size-adjust: 0.5;
}
.md-card-expand-content{
  background: #2f2f2f;
}

.md-table{
   border-radius: 4%;
    background: #6f6f6f;
}
.md-table-row {
   

  color: whitesmoke;
    background: #6f6f6f;
}


</style>


<md-card-expand-trigger>
            <md-button class="md-icon-button">
              <md-icon>keyboard_arrow_down</md-icon>
            </md-button>
          </md-card-expand-trigger>
                                   
                                    <md-card-expand-content>
          <md-card-content>
          <md-table>
            
             <md-table-row>
        <md-table-cell>Profit Fees inclued</md-table-cell>
        <md-table-cell >11111</md-table-cell>
      </md-table-row> 
       <md-table-row>
        <md-table-cell>Loss Fees inclued</md-table-cell>
        <md-table-cell >22222</md-table-cell>
      </md-table-row> 
    
       
      
       </md-table>
          </md-card-content>
        </md-card-expand-content>   

        
        /* <div class="md-layout md-alignement-center-center " >
                  <div class="md-layout-item md-size-5 md-medium-size-hide md-small-size-hide md-xsmall-hide" ></div>
                  <div   class="md-layout-item md-size-90 md-medium-size-100 md-small-size-100 md-xsmall-100" >

                   <div class="md-layout-item md-size-5 md-medium-size-hide md-small-size-hide md-xsmall-hide" ></div>