<template>
  <div class="instructions">

    <img alt="bitmex calculator instructions" src="../assets/instructions.png" width="auto">
 <h3><router-link to="/disclaimer" style="color:burlywood;">Disclaimer </router-link></h3>
  </div>
</template>

<script>
  export default {
    name: 'Instructions',
    data() {
      return {
       
      }
    },
     methods: { 
},
 metaInfo: {
    title: "Calcmex Bitmex calculator instructions fees position money-management trading",  
     link: [
    {rel: 'canonical', href: 'https://calcmex.com/instructions'}
  ],
      meta: [
          
      { name: "description", content: "Instructions to use the Bitmex calculator" },
      { name: "keywords", content: "trading, bitmex ,calculator, money-management, anti-liquidation" },    
      ]
  }
  }
</script>
<style>
.div {
width: auto;
}

</style>
