<template>
  <div class="construction">

    <h2>Only <router-link  to="/home">Bitmex XBTUSD</router-link><br><br>
    <router-link  to="/bitmexEthUs">Bitmex ETHUSD </router-link><br><br>
    <router-link  to="/bitmexBchUsd">Bitmex BCHUSD </router-link><br>and<br>
    <router-link  to="/binance_calculator">Binance pairs </router-link><br>
     available at the moment</h2>
  <h3> <a href="https://twitter.com/calcmex?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @calcmex on twitter for the latest events</a></h3>

      <div> <img alt="instructions calcmex" src="@/assets/under-construction.png"></div>

  </div>
</template>

<script>
 
  export default {
    name: 'Construction',
    data() {
      return {
        
      }
    },
    
  }
</script>
<style>
.div {
  width: auto;
}
</style>