<template>
  <div class="binance_calculator">

   <Binance />
  </div>
</template>

<script>
 import Binance from '@/components/Binance.vue'
  export default {
    name: 'Binance_calcualtor',
    components: {Binance},
    data() {
      return {
        
      }
    },
     metaInfo: {
    title: " Binance calculator fees position money-management trading anti liquidation Bitmex",  
     link: [
    {rel: 'canonical', href: 'https://calcmex.com/binance_calculator'}
  ],
      meta: [
          
      { name: "description", content: "Calcul the maximal Binance trading position size." },
      { name: "keywords", content: ", trading, binance, calculator, money-management,anti liquidation" },   
    
  
  
    // OpenGraph data (Most widely used)
    {property: 'og:title', content: 'CalcMEX Binance calculator fees position money-management trading anti liquidation'},
    {property: 'og:site_name', content: 'CalcMEX'},
    // The list of types is available here: http://ogp.me/#types
    {property: 'og:type', content: 'website'},
    // Should the the same as your canonical link, see below.
    {property: 'og:url', content: 'https://calcmex.com/#/binance_calculator'},
    {property: 'og:image', content: 'https://calcmex.com/img/CalcMEX%20Logo%20titre.4e0866d1.png'},
    // Often the same as your meta description, but not always.
    {property: 'og:description', content: 'Calcul the maximal cryptocurrency binance trading position, fees inclued, using our money-management BitMEX calculator anti liquidation'},

    // Twitter card
    {name: 'twitter:card', content: 'summary'},
    {name: 'twitter:site', content: 'https://calcmex.com'},
    {name: 'twitter:title', content: 'CalcMEX App'},
    {name: 'twitter:description', content: 'Calcul the maximal Binance trading position size.'},
    // Your twitter handle, if you have one.
    {name: 'twitter:creator', content: '@CalcMex'},
    {name: 'twitter:image:src', content: 'https://calcmex.com/img/CalcMEX%20Logo%20titre.4e0866d1.png'},

    // Google / Schema.org markup:
    {itemprop: 'name', content: 'CalcMEX App'},
    {itemprop: 'description', content: 'CalcMEX Binance integrates technicals parameters and calculates the maximal position size to trade so that it does not exceed the percentage of risk fixed (including fees) anti liquidation'},
    {itemprop: 'image', content: 'https://calcmex.com/img/CalcMEX%20Logo%20titre.4e0866d1.png'},
  
      ]
  },
  }
</script>
<style>
.div {
  width: auto;
}
</style>