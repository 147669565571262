<template>
  <div class="login">
    <h3>Sign In</h3>
   
    <div class="md-layout   md-alignment-top-center" >
      <div class="md-layout-item md-size-40 md-medium-size-30 md-small-size-20 md-xsmall-hide  "></div>
      <div class= "md-layout-item md-size-20 md-medium-size-40 md-small-size-hide md-xsmall-100  ">
        <md-field>
           <md-input class=input type="email" v-model="email" placeholder="Email"></md-input>
        </md-field>
    <md-field md-toggle-password>
         <md-input class="input  " type="password"  v-model="password" placeholder="Password" v-on:keyup.enter="login" ></md-input>
    </md-field>
     <md-button @click="login">Connection</md-button>
   </div>
   <div class="md-layout-item md-size-40 md-medium-size-30 md-small-size-20 md-xsmall-hide  "></div>
    </div>
    <p>You don't have an account ? You can <router-link to="/sign-up">create one </router-link>It's free</p>
  </div>
</template>

<script>
 import firebase from 'firebase/app';
import 'firebase/auth';
//import GoogleSignInButton from 'vue-google-signin-button-directive'

  export default {
    name: 'login',
    data() {
      return {
        email: '',
        password: '',
        clientId: '445569544771-hd4h3da4up77cghh4a4ksijhech9nnab.apps.googleusercontent.com',
      }
    },
    methods: {
        OnGoogleAuthSuccess (idToken) {
      console.log(idToken)
      // Receive the idToken and make your magic with the backend
      this.$router.replace('home')
    },
    OnGoogleAuthFail (error) {
      //console.log(error)
       alert('Oops. ' + error)
    },

      login: function() {
        firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
           () => {
           this.$router.replace('home')
          },
          (err) => {
            alert('Oops. ' + err.message)
          },
          
        );
      },
    
  
    },
  };
</script>

<style scoped>  /* "scoped" attribute limit the CSS to this component only */
  .login {
    margin-top: 40px;
  }
  input {
    margin: 10px 0;
    width: 20%;
    padding: 15px;
    border-radius: 15px;
  }
  h3 {
  margin-top:6%;
}
  p {
    margin-top: 40px;
    font-size: 16px;
  }
  p a {
    text-decoration: underline;
    cursor: pointer;
  }
  .google-signin-button {
  color: white;
  background-color: red;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
 button {
   
    background: #bdc3c7;
    cursor: pointer;
    border-radius: 15px;
  }
.md-field {
  background: #bdc3c7;
  border-radius: 6px;

}
</style>

 <div>or</div><br>
<button v-google-signin-button="clientId" class="google-signin-button"> Continue with Google</button>



 <input type="text" v-model="email" placeholder="Email"><br>
    <md-input type="password"  v-model="password" placeholder="Password" v-on:keyup.enter="login"></md-input><br>
    <button @click="login">Connection</button><br><br>


    button {
    margin-top: 20px;
    width: 10%;
    cursor: pointer;
    border-radius: 15px;
  }